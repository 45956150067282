import { Angle } from '@topremit/shared-web/components/shapes';
import { color } from '@topremit/shared-web/styles/color';
import { AnimationProps, motion } from 'framer-motion';
import { SVGProps } from 'react';

interface AngleMotionProps {
  animate: AnimationProps['animate'];
  defaultDirection: 'left' | 'right' | 'up' | 'down';
  fill?: SVGProps<SVGSVGElement>['fill'];
}

export default function AngleMotion({
  animate,
  defaultDirection,
  fill = color.neutral500,
}: AngleMotionProps) {
  return (
    <motion.div
      animate={animate}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      style={{ height: '24px', width: '24px' }}
    >
      <Angle direction={defaultDirection} size="small" fill={fill} />
    </motion.div>
  );
}
