import { css, cx } from '@emotion/css';
import { BolyNotFound } from '@shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import React from 'react';

function SearchNotFound({ className }: { className?: string }) {
  const { t } = useTranslation('home');
  return (
    <div
      className={cx(styled.customizeEmpty, className)}
      onClick={(e) => e.stopPropagation()}
    >
      <BolyNotFound width={180} height={180} />
      <div className="bold-text">{t('empty_country_currency')}</div>
    </div>
  );
}

const styled = {
  customizeEmpty: css`
    padding: 1.5rem 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
    > .bold-text {
      color: var(--text-primary);
    }
  `,
};

export default SearchNotFound;
