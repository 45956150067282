import { css, cx } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  NumberInput,
  CountryCurrencyFlag,
} from '@topremit/shared-web/components/elements';
import { FlagCode } from '@topremit/shared-web/components/elements/Flag';
import { INumberInputProps } from '@topremit/shared-web/components/elements/fields/types';
import { forwardRef } from 'react';

interface CurrencyAmountInputProps
  extends Omit<
    INumberInputProps,
    'leftSection' | 'rightSection' | 'withThousandSeparator'
  > {
  flagCode?: FlagCode;
  currencyCode?: string;
  onValueChange?: (value?: number) => void;
}

/**
 * Component for currency amount input with country flag and currency code.
 *
 * @component
 * @example
 * <CurrencyAmountInput
 *  name="test"
 *  flagCode="sg"
 *  label="Amount"
 *  currencyCode="SGD"
 *  />
 */
const CurrencyAmountInput = forwardRef<
  HTMLInputElement,
  CurrencyAmountInputProps
>(
  (
    {
      className,
      onValueChange,
      flagCode = 'id',
      decimalScale = 2,
      currencyCode = 'IDR',
      ...resProps
    },
    ref,
  ) => {
    function handleAmountChange(value?: number) {
      if (onValueChange) {
        onValueChange(value);
      }
    }

    return (
      <NumberInput
        ref={ref}
        leftSection={
          <CountryCurrencyFlag
            flagCode={flagCode}
            currencyCode={currencyCode}
          />
        }
        withThousandSeparator
        decimalScale={decimalScale}
        className={cx(styled.root, className)}
        onChange={(value) => handleAmountChange(value)}
        {...resProps}
      />
    );
  },
);

const styled = {
  root: css`
    margin: 0 !important; // override default margin from fieldgroup
    label {
      width: 96% !important; // override default label width
      text-align: right;
      padding-right: 20px;
      @media (max-width: ${screenSize.tabletSm}px) {
        padding-right: 30px;
      }
    }
    input {
      text-align: right;
    }
  `,
};

export default CurrencyAmountInput;
