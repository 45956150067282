import { cx } from '@emotion/css';
import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';
import { Flex } from '@topremit/shared-web/components/elements';

import ChooseDeliverySpeedItem from './ChooseDeliverySpeedItem';
import { chooseDeliverySpeedStyle as styled } from './styles';
import { useDeliverySpeed } from './use-delivery-speed';

/**
 * Component for displaying the content of choose delivery speed which contains the delivery speed options.
 */
export default function ChooseDeliverySpeedContent() {
  const {
    options,
    value,
    disabled,
    showDiscountFee,
    onValueChange,
    onOpenChange,
  } = useDeliverySpeed();

  function handleClickItem(
    serviceId: number,
    routingChannel: RoutingChannelType,
  ) {
    onValueChange({
      serviceId,
      routingChannel,
    });
    onOpenChange?.(false);
  }
  return (
    <Flex
      className={cx(styled.contentWrapper, {
        [styled.disabled]: disabled,
      })}
    >
      <Flex column>
        {options.map((option, index) => {
          const routingChannel = option.routingChannels[0];
          const routingChannelType = routingChannel.type;

          const checked = value?.routingChannel === routingChannelType;
          const lastOption = options.length - 1 === index;

          const hasDiscountFee =
            showDiscountFee && !!routingChannel.discount?.value;

          const discountFeeValue = hasDiscountFee
            ? routingChannel.discount?.value
            : 0;

          return (
            <ChooseDeliverySpeedItem
              checked={checked}
              name="routingChannel"
              key={routingChannelType}
              fee={routingChannel.fee}
              withDivider={!lastOption}
              value={routingChannelType}
              label={routingChannel.label}
              disabled={routingChannel.disabled}
              discountFeeValue={discountFeeValue}
              description={routingChannel.description}
              volumeExceededDescription={
                routingChannel.volumeExceededDescription
              }
              onClick={() => handleClickItem(option.id, routingChannelType)}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}
