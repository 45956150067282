import { ContainerSpinner } from '@global-elements';
import dynamic, { DynamicOptions, Loader } from 'next/dynamic';
import { createElement } from 'react';

export function dynamicImportWithSpinner<T = object>(
  importPath: DynamicOptions<T> | Loader<T>,
) {
  return dynamic(importPath, {
    loading: () => createElement(ContainerSpinner),
  });
}
