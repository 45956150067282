import { createContext, useContext } from 'react';

import { DeliverySpeedContextValue } from './types';

export const DeliverySpeedContext =
  createContext<DeliverySpeedContextValue | null>(null);

/**
 * Hook for using the delivery speed context.
 */
export const useDeliverySpeed = (): DeliverySpeedContextValue => {
  const context = useContext(DeliverySpeedContext);
  if (!context) {
    throw new Error(
      'useDeliverySpeed must be used within a DeliverySpeedProvider',
    );
  }
  return context;
};
