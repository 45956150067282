import { DiscountedFeePillsContent } from '@elements';
import { cx } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Divider,
  Flex,
  parseHtml,
  Radio,
  Status,
  Text,
} from '@topremit/shared-web/components/elements';
import { useMediaQuery } from '@topremit/shared-web/hooks';

import { chooseDeliverySpeedStyle as styled } from './styles';
import { ChooseDeliverySpeedItemProps } from './types';

/**
 * Component for displaying the delivery speed options.
 */
export default function ChooseDeliverySpeedItem({
  fee,
  name,
  value,
  label,
  checked,
  disabled,
  description,
  withDivider,
  discountFeeValue,
  volumeExceededDescription,
  onClick,
}: ChooseDeliverySpeedItemProps) {
  const isMobileSize = useMediaQuery(`(max-width: ${screenSize.tabletMd}px)`);

  const itemDescription = disabled ? volumeExceededDescription : description;

  function handleClickItem() {
    if (disabled || checked) {
      return;
    }

    onClick();
  }

  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        className={styled.item}
        onClick={handleClickItem}
      >
        <Flex column>
          <Flex gap={8} align="center">
            <Text
              className={cx(styled.title, {
                [styled.textDisabled]: disabled,
              })}
            >
              {label}
            </Text>
            <Status
              message={
                <DiscountedFeePillsContent
                  normalFee={fee}
                  discountFeeValue={discountFeeValue ?? 0}
                />
              }
              status={disabled ? 'expired' : 'active'}
              size={isMobileSize ? 'small' : 'medium'}
            />
          </Flex>
          <Flex
            column
            className={cx(styled.description, {
              [styled.textDisabled]: disabled,
            })}
          >
            {parseHtml(itemDescription)}
          </Flex>
        </Flex>
        <Radio
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
        />
      </Flex>
      {withDivider && <Divider variant="dashed" />}
    </>
  );
}
