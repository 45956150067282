import { css, cx } from '@emotion/css';
import bulletPointImage from '@public/images/svg/bullet-point.svg';
import { parseHtml } from '@topremit/shared-web/components/elements';
import Image from 'next/image';

interface IListItemProps {
  type?: 'primary' | 'secondary';
  content: string;
  className?: string;
}

export default function ListItem({
  type = 'primary',
  content,
  className,
}: IListItemProps) {
  return (
    <div className={cx(styled.root, className)}>
      <div className="img-wrapper">
        <Image src={bulletPointImage} width={8} height={8} alt="bullet point" />
      </div>
      <div
        className={cx(
          'content',
          type === 'primary' ? 'sm-text' : 'sm-bold-text',
          { secondary: type === 'secondary' },
        )}
      >
        <span>{parseHtml(content)}</span>
      </div>
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    margin-bottom: 0.5rem;
    .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      height: 19.6px;
    }
    .secondary {
      color: var(--text-secondary);
    }
  `,
};
