import { AnimatedHeightWrapper, CurrencyAmountInput } from '@elements';
import { css, cx } from '@emotion/css';
import AdditionalNotesInput from '@modules/recipient/AdditionalNotesInput';
import { Card } from '@topremit/shared-web/components/elements';
import { FlagCode } from '@topremit/shared-web/components/elements/Flag';

interface AmountSummaryCardProps {
  className?: string;
  amountName: string;
  amountLabel: string;
  notesLabel?: string;
  notesValue: string;
  amountValue?: number;
  flagCode?: FlagCode;
  currencyCode?: string;
  onNotesValueChange: (value: string) => void;
  onAmountValueChange?: (value?: number) => void;
}

/**
 * A card component that combines a currency amount input and an additional notes input.
 * It is designed to handle both values with optional callbacks for external state management.
 *
 * @component
 * @example
 * <AmountSummaryCard
 *   flagCode="id"
 *   currencyCode="IDR"
 *   notesValue={notes}
 *   amountName="amount"
 *   amountLabel="Amount"
 *   amountValue={amount}
 *   notesLabel="Additional notes"
 *   onNotesValueChange={(value) => setNotes(value)}
 *   onAmountValueChange={(value) => setAmount(value)}
 * />
 */
function AmountSummaryCard({
  flagCode,
  className,
  amountName,
  notesLabel,
  notesValue,
  amountLabel,
  amountValue,
  currencyCode,
  onNotesValueChange,
  onAmountValueChange,
}: AmountSummaryCardProps) {
  function handleAmountChange(value?: number) {
    if (onAmountValueChange) {
      onAmountValueChange(value);
    }
  }

  function handleNotesChange(value: string) {
    if (onNotesValueChange) {
      onNotesValueChange(value);
    }
  }

  return (
    <Card className={cx(styled.root, className)}>
      <AnimatedHeightWrapper className={styled.wrapper}>
        <CurrencyAmountInput
          value={amountValue}
          label={amountLabel}
          name={amountName}
          flagCode={flagCode}
          className={styled.amount}
          currencyCode={currencyCode}
          onValueChange={handleAmountChange}
        />
        <AdditionalNotesInput
          label={notesLabel}
          value={notesValue}
          onValueChange={handleNotesChange}
        />
      </AnimatedHeightWrapper>
    </Card>
  );
}

const styled = {
  root: css`
    padding: 16px;
    border-radius: 16px;
  `,
  wrapper: css`
    width: 100%;
  `,
  amount: css`
    margin-bottom: 12px !important;
  `,
};

export default AmountSummaryCard;
