import { AngleMotion, DiscountedFeePillsContent } from '@elements';
import { cx } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { Flex, Status, Text } from '@topremit/shared-web/components/elements';
import { useMediaQuery } from '@topremit/shared-web/hooks';
import { useEffect, useRef, useState } from 'react';

import { chooseDeliverySpeedStyle as styled } from './styles';
import { useDeliverySpeed } from './use-delivery-speed';

/**
 * Component for displaying the choosed delivery speed.
 */
export default function ChoosedDeliverySpeed() {
  const isMobileSize = useMediaQuery(`(max-width: ${screenSize.tabletMd}px)`);

  const [clientHeight, setClientHeight] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  const {
    open,
    value,
    options,
    disabled,
    selectedValue,
    showDiscountFee,
    onOpenChange,
  } = useDeliverySpeed();

  const routingChannel = selectedValue?.routingChannels.find(
    (routingChannel) => routingChannel.type === value.routingChannel,
  );

  const { fee, discount } = routingChannel ?? {};

  const discountValue = discount?.value;

  const hasMultipleOptions = options.length > 1;

  const hasDiscountFee = showDiscountFee && Number(discountValue ?? 0) > 0;
  const discountFeeValue = hasDiscountFee ? discountValue : 0;

  useEffect(() => {
    setClientHeight(ref.current?.clientHeight || 0);
  }, [open]);

  return (
    <Flex
      align="center"
      justify="space-between"
      className={cx(styled.choosedItemWrapper(hasMultipleOptions), {
        [styled.disabled]: disabled,
      })}
      onClick={() => {
        if (disabled) {
          return;
        }
        if (hasMultipleOptions) {
          onOpenChange?.(true);
        }
      }}
    >
      <Flex column>
        <Flex
          gap={12}
          align="center"
          justify="space-between"
          className={styled.choosedItem(clientHeight)}
        >
          <Flex column>
            <Text className={styled.title}>{routingChannel?.label}</Text>
            <Flex column className={styled.description}>
              {routingChannel?.etaDescription}
            </Flex>
          </Flex>
          <Flex className={styled.feeWrapper} align="center">
            <Status
              status="active"
              message={
                <DiscountedFeePillsContent
                  normalFee={fee ?? 0}
                  discountFeeValue={discountFeeValue ?? 0}
                />
              }
              size={isMobileSize ? 'small' : 'medium'}
            />
            {hasMultipleOptions && (
              <AngleMotion
                defaultDirection="up"
                animate={{ rotate: open ? 0 : -180 }}
              />
            )}
          </Flex>
        </Flex>
        <Flex
          ref={ref}
          align="center"
          justify="center"
          className={styled.choosedItemNote}
        >
          {routingChannel?.note}
        </Flex>
      </Flex>
    </Flex>
  );
}
