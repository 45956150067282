import { css, cx } from '@emotion/css';
import { DividerVariant } from '@global-elements-utils/Divider';
import { isStringEmpty } from '@topremit/shared-web/common/helper';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  CopyButton,
  Divider,
  parseHtml,
  Tooltip,
} from '@topremit/shared-web/components/elements';
import Flag, { FlagCode } from '@topremit/shared-web/components/elements/Flag';
import { Info } from '@topremit/shared-web/components/shapes';
import { useMediaQuery } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';
import { motion } from 'framer-motion';
import { CSSProperties } from 'react';

export interface IInfoField {
  type?: 'row' | 'column';
  label: string;
  value: string;
  flagCode?: FlagCode;
  divider?: boolean;
  dividerInfo?: {
    position?: 'top' | 'bottom';
    type?: DividerVariant;
  };
  secondaryText?: boolean;
  className?: string;
  canCopy?: boolean;
  tooltipLabel?: string;
  labelStyles?: CSSProperties;
  valueStyles?: CSSProperties;
  normalFee?: string | boolean;
  additionalNote?: React.ReactNode;
  showAnimationValue?: boolean;
}

export default function InfoField({
  type = 'row',
  label,
  value,
  divider,
  dividerInfo,
  secondaryText,
  className,
  flagCode,
  canCopy,
  labelStyles,
  valueStyles,
  tooltipLabel,
  normalFee,
  additionalNote,
  showAnimationValue = false,
}: IInfoField) {
  const mergedDividerInfo = {
    type: 'solid' as DividerVariant,
    position: 'bottom',
    ...dividerInfo,
  };
  const isMobileSize = useMediaQuery(`(max-width: ${screenSize.tabletMd}px)`);
  const isDividerTop = divider && mergedDividerInfo?.position === 'top';
  const isDividerBottom = divider && mergedDividerInfo?.position === 'bottom';

  return (
    <div className={cx(styled.root, className)}>
      {isDividerTop && <Divider variant={mergedDividerInfo.type} my={16} />}
      <div className={`${type}-wrapper`}>
        <div
          className={cx(
            {
              'grey-text': secondaryText,
              'xs-text': type === 'column',
            },
            'label',
          )}
          {...(labelStyles && {
            style: labelStyles,
          })}
        >
          <span>{label}</span>
          {!isStringEmpty(tooltipLabel) && (
            <Tooltip
              placement={isMobileSize ? 'top' : 'bottom'}
              className={styled.tooltip}
              item={{
                content: parseHtml(String(tooltipLabel)),
              }}
            >
              <Info width={16} height={16} fill={color.blue500} />
            </Tooltip>
          )}
        </div>
        <div
          className="value"
          {...(valueStyles && {
            style: valueStyles,
          })}
        >
          {flagCode && (
            <div className="icon-flag">
              <Flag code={flagCode} />
            </div>
          )}
          {normalFee && <span className="strikethrough">{normalFee}</span>}

          <motion.div
            animate={
              showAnimationValue
                ? {
                    y: [0, 15, -15, 0],
                    opacity: [1, 0.5, 0, 0.5, 1],
                  }
                : {}
            }
          >
            {value || '-'}
          </motion.div>
          {canCopy && (
            <CopyButton
              data={value}
              label={label}
              type="icon"
              className="copy-button"
            />
          )}
        </div>
      </div>
      {additionalNote && additionalNote}
      {isDividerBottom && <Divider variant={mergedDividerInfo?.type} mt={16} />}
    </div>
  );
}
const styled = {
  root: css`
    margin-bottom: 0.75rem;
    .column-wrapper {
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      @media (max-width: ${screenSize.tabletMd}px) {
        font-size: var(--sm-font-size);
      }
      > .value {
        text-align: unset;
      }
    }
    .row-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 1rem;
      @media (max-width: ${screenSize.tabletMd}px) {
        font-size: var(--sm-font-size);
      }
      > .value {
        max-width: 65%;
        margin-left: 12px;
      }
    }
    .green-text {
      color: var(--green-500);
    }
    .grey-text {
      color: var(--text-secondary);
    }
    .label {
      color: var(--text-primary);
      margin-right: auto;
      display: flex;
      max-width: 45%;
    }
    .value {
      word-break: break-word;
      text-align: right;
      display: flex;
      align-items: center;
      .copy-button {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
      }
      .icon-flag {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
      }
      .strikethrough {
        margin-right: 0.25rem;
        font-size: var(--xs-font-size);
      }
    }
    .dashed-divider {
      display: flex;
      overflow: hidden;
      margin: 1rem 0;
      > img {
        width: 100%;
      }
    }
  `,

  tooltip: css`
    display: flex;
    align-items: center;
    margin-left: 0.5rem;

    .ant-popover-content {
      .ant-popover-arrow {
        > span {
          background-color: var(--neutral-0);
        }
      }
      .ant-popover-inner {
        .ant-popover-title {
          min-width: unset;
          min-height: unset;
          > div {
            padding: 0;
          }
        }
        .ant-popover-inner-content {
          background-color: var(--neutral-0);
          border-radius: 0.875rem;
          > div {
            z-index: 999 !important;
            padding: 0.875rem 1.25rem;
            font-size: var(--sm-font-size);
          }
        }
      }
    }
  `,
};
