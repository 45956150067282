import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { color } from '@topremit/shared-web/styles/color';

export const chooseDeliverySpeedStyle = {
  root: css`
    overflow: hidden;
    position: relative;
  `,
  item: css`
    cursor: pointer;
    padding: 12px 0;
    gap: 12px;
    @media (max-width: ${screenSize.tabletMd}px) {
      gap: 8px;
    }
  `,
  choosedItem: (noteHeight: number) => css`
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: ${12 + noteHeight}px; // 12px padding + note height
  `,
  choosedItemWrapper: (hasMultipleOptions: boolean) => css`
    cursor: ${hasMultipleOptions ? 'pointer' : 'default'};
  `,
  trigger: css`
    padding: 21px 16px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    @media (max-width: ${screenSize.tabletMd}px) {
      padding: 18px 16px;
    }
  `,
  contentWrapper: css`
    padding: 0 16px 8px 16px;
  `,
  title: css`
    font-weight: var(--bold-font-weight);
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: var(--sm-font-size);
    }
  `,
  description: css`
    margin-top: 4px;
    color: ${color.neutral500};
    font-size: var(--sm-font-size);

    ul {
      padding-left: 24px;
    }

    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: var(--xs-font-size);
    }
  `,
  choosedItemNote: css`
    color: ${color.neutral500};
    font-size: var(--xs-font-size);
    padding: 8px 16px;
    background-color: ${color.neutral100};
    width: 100%;
    position: absolute;
    text-align: center;
    bottom: 0;
    border-top: 1px solid ${color.neutral200};
  `,
  feeWrapper: css`
    width: unset;
    gap: 12px;
    @media (max-width: ${screenSize.tabletMd}px) {
      gap: 8px;
    }
  `,
  disabled: css`
    background-color: ${color.neutral100};
  `,
  redBorder: css`
    border: 1px solid ${color.red500} !important; // override the .card-secondary default border
  `,
  textDisabled: css`
    color: ${color.neutral300};
  `,
};
