import { css, cx } from '@emotion/css';
import { screenSize, typography } from '@topremit/shared-web/common/size';
import {
  Button,
  ListMenu,
  TextInput,
} from '@topremit/shared-web/components/elements';
import { Pencil } from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { color } from '@topremit/shared-web/styles/color';
import { FormProvider, useForm } from 'react-hook-form';

interface AdditionalNotesDisplayProps {
  value: string;
  label?: string;
}

interface AdditionalNotesInputProps extends AdditionalNotesDisplayProps {
  className?: string;
  onValueChange: (value: string) => void;
}

interface AdditionalNotesDialogProps {
  label?: string;
  initialValue?: string;
  onSave: (value: string) => void;
}

interface FormModel {
  additionalNotes: string;
}

const MAX_RECIPIENT_NAME_LENGTH = 16;

/**
 * Component for managing additional notes input with an optional dialog.
 * @component
 * @example
 * <AdditionalNotesInput
 *   value={value}
 *   onValueChange={(val)=> setValue(val)}
 *   label='Notes for recipient'
 * />
 */
function AdditionalNotesInput({
  label,
  value,
  className,
  onValueChange,
}: AdditionalNotesInputProps) {
  const pushDialog = useDialogStore((s) => s.push);

  function handleValueChange(val: string) {
    onValueChange(val);
  }

  function handleOpenDialog() {
    pushDialog({
      body: (
        <AdditionalNotesDialog
          label={label}
          initialValue={value}
          onSave={handleValueChange}
        />
      ),
    });
  }

  return (
    <ListMenu
      type="arrow"
      arrowWidth={16}
      arrowHeight={16}
      onClick={handleOpenDialog}
      className={cx(styled.additionalNotes, className)}
      title={<AdditionalNotesDisplay label={label} value={value} />}
    />
  );
}

/**
 * Displays the current value of the additional notes input.
 */
function AdditionalNotesDisplay({
  value,
  label: externalLabel,
}: AdditionalNotesDisplayProps) {
  const { t } = useTranslation('transaction');

  const hasValue = Boolean(value);
  const label = externalLabel ?? t('domestic.additional_notes_optional');

  return (
    <div className={styled.additionalNotesWrapper(hasValue)}>
      <Pencil aria-hidden="true" />
      <span>{value || label}</span>
    </div>
  );
}

/**
 * Dialog for editing additional notes.
 */
function AdditionalNotesDialog({
  label,
  initialValue,
  onSave,
}: AdditionalNotesDialogProps) {
  const { t } = useTranslation('transaction');
  const popDialog = useDialogStore((s) => s.pop);

  const methods = useForm<FormModel>({
    defaultValues: { additionalNotes: initialValue || '' },
  });

  function handleOnSubmit({ additionalNotes }: FormModel) {
    onSave(additionalNotes);
    popDialog();
  }

  return (
    <FormProvider {...methods}>
      <form
        className={styled.form}
        onSubmit={methods.handleSubmit(handleOnSubmit)}
      >
        <h5>{t('domestic.write_additional_notes')}</h5>

        <TextInput
          showCount
          name="additionalNotes"
          maxLength={MAX_RECIPIENT_NAME_LENGTH}
          helperText={t('domestic.additional_notes_helper')}
          label={label ?? t('domestic.additional_notes_optional')}
        />

        <Button fullWidth htmlType="submit" className={styled.submit}>
          {t('common:continue')}
        </Button>
      </form>
    </FormProvider>
  );
}

const styled = {
  additionalNotes: css`
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: ${color.neutral100};
  `,
  additionalNotesWrapper: (hasValue: boolean) => css`
    gap: 12px;
    display: flex;
    align-items: center;
    font-size: ${typography.smallMedium.fontSize}rem;
    color: ${hasValue ? color.neutral800 : color.neutral500};
    @media (max-width: ${screenSize.tabletSm}px) {
      gap: 8px;
      font-size: ${typography.extraSmallMedium.fontSize}rem;
    }
  `,
  form: css`
    width: 100%;
  `,
  submit: css`
    margin-top: 16px;
  `,
};

export default AdditionalNotesInput;
