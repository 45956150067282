import { formatToIDRCurrency } from '@topremit/shared-web/common/helper';
import { useTranslation } from '@topremit/shared-web/hooks';

interface DiscountedFeePillsProps {
  normalFee: number;
  discountFeeValue: number;
}

function DiscountedFeePillsContent({
  normalFee,
  discountFeeValue,
}: DiscountedFeePillsProps) {
  const { t } = useTranslation();

  const feeInIdr = formatToIDRCurrency(normalFee);
  const discountedFee = discountFeeValue
    ? normalFee - Number(discountFeeValue)
    : normalFee;
  const discountedFeeInIdr = formatToIDRCurrency(discountedFee);

  return (
    <>
      <div>
        {t('fee')} {discountedFeeInIdr}
      </div>
      {Number(discountFeeValue ?? 0) > 0 && (
        <s className="secondary-text xs-text">{feeInIdr}</s>
      )}
    </>
  );
}

export default DiscountedFeePillsContent;
