import { AngleMotion } from '@elements';
import { cx } from '@emotion/css';
import { Flex, Text } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';

import { chooseDeliverySpeedStyle as styled } from './styles';
import { useDeliverySpeed } from './use-delivery-speed';

/**
 * Component for displaying the trigger to choose delivery speed.
 */
export default function ChooseDeliverySpeedTrigger() {
  const { t } = useTranslation();
  const { open, onOpenChange, disabled } = useDeliverySpeed();

  function handleOpenChange(open: boolean) {
    if (disabled) {
      return;
    }
    onOpenChange?.(open);
  }
  return (
    <Flex
      gap={8}
      align="center"
      justify="space-between"
      className={cx(styled.trigger, {
        [styled.disabled]: disabled,
      })}
      onClick={() => handleOpenChange(!open)}
    >
      <Text className={styled.title}>{t('select_delivery_speed')}</Text>
      <AngleMotion
        defaultDirection="down"
        animate={{ rotate: open ? 180 : 0 }}
      />
    </Flex>
  );
}
