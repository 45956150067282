import { AnimatedHeightWrapper } from '@elements';
import { cx } from '@emotion/css';
import { ErrorMessage, Flex } from '@topremit/shared-web/components/elements';
import { useState } from 'react';

import ChooseDeliverySpeedContent from './ChooseDeliverySpeedContent';
import ChooseDeliverySpeedTrigger from './ChooseDeliverySpeedTrigger';
import ChoosedDeliverySpeed from './ChoosedDeliverySpeed';
import { chooseDeliverySpeedStyle as styled } from './styles';
import { ChooseDeliverySpeedProps } from './types';
import { DeliverySpeedContext } from './use-delivery-speed';

/**
 * Component for displays a card with a trigger, choosedSpeed and content that contain delivery speeds.
 */
export default function ChooseDeliverySpeed(props: ChooseDeliverySpeedProps) {
  const {
    value,
    error,
    options,
    className,
    open: openProps,
    onClick,
    onOpenChange: onOpenChangeProps,
  } = props;

  const [internalOpenState, setInternalOpenState] = useState<boolean>(false);

  const isControlled = openProps !== undefined;
  const open = isControlled ? openProps : internalOpenState;

  const hasValue = value.routingChannel && value.serviceId;

  const selectedValue = options.find(
    (option) =>
      option.id === value.serviceId &&
      option.routingChannels.some(
        (routingChannel) => routingChannel.type === value.routingChannel,
      ),
  );

  function handleOpenChange(open: boolean) {
    if (onOpenChangeProps) {
      onOpenChangeProps(open);
    } else {
      setInternalOpenState(open);
    }
  }

  return (
    <DeliverySpeedContext.Provider
      value={{ ...props, selectedValue, open, onOpenChange: handleOpenChange }}
    >
      <>
        <Flex
          column
          className={cx(
            'card card-secondary',
            styled.root,
            {
              [styled.redBorder]: Boolean(error),
            },
            className,
          )}
          onClick={onClick}
        >
          <AnimatedHeightWrapper style={{ width: '100%' }}>
            {hasValue && !open ? (
              <ChoosedDeliverySpeed />
            ) : (
              <>
                <ChooseDeliverySpeedTrigger />
                {open && <ChooseDeliverySpeedContent />}
              </>
            )}
          </AnimatedHeightWrapper>
        </Flex>
      </>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </DeliverySpeedContext.Provider>
  );
}
