import { css, cx } from '@emotion/css';
import { QuotationNoteModel } from '@global-api-hooks/transaction';
import { screenSize } from '@global-common/size';
import { VariantCard } from '@global-elements';
import { useDialogStore } from '@global-stores';
import { dynamicImportWithSpinner } from 'landing/../shared/common/import';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import ListItem from './ListItem';

const QuotationOtherDetailsModalBody = dynamicImportWithSpinner(
  () => import('@modules/landing/jumbotron/QuotationOtherDetailsModalBody'),
);

interface IQutationNoteProps {
  className?: string;
  data: QuotationNoteModel[] | undefined;
}

interface IUseShowNoteDetailDialogProps {
  isShow: boolean;
  content: QuotationNoteModel['content'] | undefined;
  onClose: () => void;
}

const SHOW_NOTE_KEY = '#show-note';

/**
 * Quotation note component with list of notes
 * Can show note detail dialog when click on note with hash #show-note
 */
export default function QuotationNote({ data, className }: IQutationNoteProps) {
  const router = useRouter();

  const [currentHash, setCurrentHash] = useState('');

  const isShowNote = currentHash === SHOW_NOTE_KEY;
  const notesDialogContent = data?.[data.length - 1]?.content;

  useListenHashChange(currentHash, setCurrentHash);

  useShowNoteDetailDialog({
    isShow: isShowNote,
    content: notesDialogContent,
    onClose: handleCloseDetail,
  });

  function handleCloseDetail() {
    setCurrentHash('');
    router.replace({ pathname: '', query: { ...router.query } }, undefined, {
      shallow: true,
    });
  }

  return (
    <VariantCard
      type="info"
      className={cx(styles.note, className)}
      content={<NoteList data={data} />}
    />
  );
}

function NoteList({ data }: Pick<IQutationNoteProps, 'data'>) {
  return (
    <>
      {data?.map(({ note }, idx) => (
        <ListItem key={idx} content={note} className={styles.item} />
      ))}
    </>
  );
}

/**
 * Show note dialog detail when isShow is true and content is not undefined
 */
function useShowNoteDetailDialog({
  content,
  isShow,
  onClose,
}: IUseShowNoteDetailDialogProps) {
  const showDialog = useDialogStore((s) => s.show);
  const closeDialog = useDialogStore((s) => s.close);

  useEffect(() => {
    if (isShow && content) {
      showDialog({
        body: <QuotationOtherDetailsModalBody notes={content} />,
        onClose: () => {
          closeDialog();
          onClose();
        },
      });
    }
  }, [isShow, content, showDialog, onClose, closeDialog]);
}

/**
 * Listen hash (eg: #show-note) in the router change and call onHashChange when hash change
 */
function useListenHashChange(
  hash: string,
  onHashChange: (hash: string) => void,
) {
  useEffect(() => {
    function handleHashChange() {
      onHashChange(window.location.hash ?? '');
    }

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [hash, onHashChange]);
}

const styles = {
  note: css`
    width: 100%;
    padding: 8px 16px;
    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  `,
  item: css`
    margin-bottom: 0;
    .img-wrapper {
      margin-right: 16px;
    }
    .content {
      a {
        font-weight: bold;
      }
      @media (max-width: ${screenSize.tabletMd}px) {
        font-size: 12px;
      }
    }
  `,
};
